function SearchItem({search, setSearch}) {
	return (
		<form className="searchForm" onSubmit={(e) => e.preventDefault()}>
			<label htmlFor="search"></label>
			<input
				id="search"
				type="text"
				role="searchbox"
				placeholder="Zoek gerecht..."
				value={search}
				onChange={(e) => setSearch(e.target.value)}

			/>
			<button onClick={() => setSearch('')}>🚫</button>
		</form>

	)
}

export default SearchItem