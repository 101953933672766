import { useRef, useState } from 'react';

function WeekList({weekList = [], removeFromWeekList, displayWeekList, addRandomRecipe, setDisplayWeekList, clearWeekList}) {

	const [displayIngredientsList, setDisplayIngredientsList] = useState(false);
  	const [ingredientsList, setIngredientsList] = useState([]);
  	const [copied, setCopied] = useState(false);

function copyToClipboard() {
  const ingredientsText = ingredientsList.join("\n");

  if (navigator.clipboard) {
    navigator.clipboard.writeText(ingredientsText)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
    const textarea = document.createElement("textarea");
    textarea.value = ingredientsText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    setCopied(true);
  }
}

	function getAllIngredients(weekList) {
		console.log('hey');
  return weekList.reduce((acc, recipe) => {
    return [...acc, ...recipe.ingredients]
  }, [])
}

  const toggleIngredientsList = () => {
    const allIngredients = weekList.reduce(
      (ingredients, recipe) => [...ingredients, ...recipe.ingredients],
      []
    );
    setIngredientsList(allIngredients);
    setDisplayIngredientsList(!displayIngredientsList);
  };

  const removeIngredient = (index) => {
    const newIngredientsList = [...ingredientsList];
    newIngredientsList.splice(index, 1);
    setIngredientsList(newIngredientsList);
  };


	return (
  <>
    {displayWeekList && (
      <section className="weeklistSection">
        <button className="closeMe" onClick={() => setDisplayWeekList(false)}>
          X
        </button>

        {weekList.length === 0 ? (
          <div className="weekListLeeg">Weeklist is leeg!</div>
        ) : (
          <>
            {weekList.map((recipe) => (
              <div className="weeklist" key={recipe.id}>
                <img src={recipe.imageurl} />
                <h1>{recipe.title}</h1>
                <span>{recipe.time}</span>
                <button onClick={() => removeFromWeekList(recipe.id)}>Delete</button>
              </div>
            ))}
            {weekList.length !== 0 && <button onClick={toggleIngredientsList}>Ingrediëntenlijst</button>}
          </>
        )}
        <div className="weekListBtns">
        <button className="randomBtn" onClick={addRandomRecipe}>
          🔄 Add Random Recipe
        </button>
        <button onClick={clearWeekList}>🚫 Clear weeklist</button>
        </div>
      </section>
    )}

              <>
       
                {displayIngredientsList && (
        <div className="ingredientsList">
          <button className="closeMe igList" onClick={toggleIngredientsList}>
            X
          </button>
          <h2>Ingredients List</h2>
          <ul>
            {ingredientsList.map((ingredient, index) => (
              <li key={index}>
                {ingredient}
                <button onClick={() => removeIngredient(index)}>X</button>
              </li>
            ))}
          </ul>
                <button className="copyBtn" onClick={copyToClipboard}>
        {copied ? "Gekopieerd!" : "Kopiëer"}
      </button>
        </div>
      )}
              </>

  </>
);
}

export default WeekList