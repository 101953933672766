import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import {auth, storage} from '../../firebase.config.js';


import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

function RecipeForm({recipe = null, onSave, setAddRecipe, handleSaveRecipe, setEditingRecipe}) {


  const [title, setTitle] = useState(recipe?.title || "");
  const [ingredients, setIngredients] = useState(recipe?.ingredients || ['']);
  const [steps, setSteps] = useState(recipe?.steps || ['']);
  const [imageurl, setImageUrl] = useState(recipe?.imageurl || "");
  const [videolink, setVideoLink] = useState(recipe?.videolink || "");
  const [imageFile, setImageFile] = useState(null);

  const [season, setSeason] = useState(recipe?.season || '');
  const [time, setTime] = useState(recipe?.time || '');

  const [user] = useAuthState(auth);

  const [auteur, setAuteur] = useState(user.displayName.split(" ")[0]);
  console.log(auteur);

  const handleAddIngredient = () => {
    setIngredients([...ingredients, ""]);
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImageFile(selectedImage);
  };

  const handleRemoveIngredient = (index) => {
    const newIngredients = [...ingredients];
    newIngredients.splice(index, 1);
    setIngredients(newIngredients);
  };

  const handleIngredientChange = (value, index) => {
    const newIngredients = [...ingredients];
    newIngredients[index] = value;
    setIngredients(newIngredients);
  };

  const handleAddStep = () => {
    setSteps([...steps, ""]);
  };

  const handleRemoveStep = (index) => {
    const newSteps = [...steps];
    newSteps.splice(index, 1);
    setSteps(newSteps);
  };

  const handleStepChange = (index, event) => {
    const newSteps = [...steps];
    newSteps[index] = event;
    setSteps(newSteps);
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const handleSubmit = async (event) => {
    console.log(recipe);
    event.preventDefault();

    if(recipe){

    const editedRecipe = {
          ...recipe,
          title,
          ingredients,
          steps,
          imageurl,
          videolink,
          auteur,
          season,
          time,
        };

     handleSaveRecipe(editedRecipe);
     return;
    }

    console.log('not good');

if (!imageFile?.name) {
  alert('Please add a photo!');
  return;
}

    const storageRef = ref(storage, `users/${user.uid}/images/${imageFile.name}`);
    const uploadTask = uploadBytes(storageRef, imageFile);

uploadTask.then(async (snapshot) => {
  // handle upload success here

  // get the download URL for the image
  const downloadURL = await getDownloadURL(storageRef);

  // create the new recipe object with the image URL
  const newRecipe = {
    id: recipe?.id || Math.floor(Math.random() * 100),
    title,
    ingredients,
    steps,
    imageurl: downloadURL,
    videolink,
    auteur,
    season,
    time,
  };

  setTitle('');
  setIngredients(['']);
  setSteps(['']);
  setImageUrl('');
  setVideoLink('');
  setSeason('');
  setTime('');

  onSave(newRecipe);

}).catch((error) => {
  // handle error here
});

}

const closeRecipe = () => {
 setTitle('');
 setIngredients(['']);
 setSteps(['']);
 setImageUrl('');
 setVideoLink('');
 setAddRecipe(false);
 setImageFile(false);
 setEditingRecipe(false);
}



return (
  <div className="theForm">
  <div className="closeMe" onClick={closeRecipe}>X</div>
  <h2>{recipe ? 'Aanpassen' : 'Gerecht Toevoegen'}</h2>


  <form onSubmit={handleSubmit}>
  <label>
  Title:
  <input
  type="text"
  value={title}
  onChange={(e) => setTitle(e.target.value)}
  required
  />
  </label>
  <label>
  Ingredients:
  {ingredients.map((ingredient, index) => (
    <div key={index}>
    <input
    type="text"
    value={ingredient}
    onChange={(e) => handleIngredientChange(e.target.value, index)}
    />
    <button className="removeItem" type="button" onClick={() => handleRemoveIngredient(index)}>
    🚫
    </button>
    </div>
    ))}
  <button type="button" onClick={handleAddIngredient}>
  Add Ingredient
  </button>
  </label>
  <label>
  Steps:
  {steps.map((step, index) => (
    <div key={index}>
    <textarea
    value={step}
    onChange={(e) => handleStepChange(index, e.target.value)}
    />
    <button type="button" className="removeItem" onClick={() => handleRemoveStep(index)}>
    🚫
    </button>
    </div>
    ))}
  <button type="button" onClick={handleAddStep}>
  Add Step
  </button>
  </label>
  <label>
  Image:
  <input type="file" accept="image/*" onChange={handleImageChange} />
  </label>
  <label>
  Video URL:
  <input
  type="text"
  value={videolink}
  onChange={(e) => setVideoLink(e.target.value)}
  />
  </label>
        <label>
        Seizoen:
        <select value={time} onChange={(e) => setTime(e.target.value)}>
          <option value="">--Select--</option>
          <option value="Zomer">Zomer</option>
          <option value="Winter">Winter</option>
        </select>
      </label>
            <label>
        Tijd nodig:
        <select value={time} onChange={(e) => setTime(e.target.value)}>
          <option value="">--Select--</option>
          <option value="20min">20 minuten</option>
          <option value="40min">40 minuten</option>
          <option value="1u">1 uur</option>
          <option value="1u30">1u30</option>
          <option value="2u">2u</option>
          <option value="+2u">Meer dan 2u</option>
        </select>
      </label>

  <button type="submit">{recipe ? 'Save Recipe' : 'Add Recipe'}</button>


  </form>

  </div>
  )
}

export default RecipeForm