import './App.css';
import { useState, useEffect } from 'react';
import MainGrid from './components/MainGrid/MainGrid';
import RecipeForm from './components/RecipeForm/RecipeForm';
import SearchItem from './components/SearchItem/SearchItem'
import WeekList from './components/WeekList/WeekList';
import Navbar from './components/Navbar/Navbar';
import { collection, addDoc, doc, query, where, getDocs, getDoc, onSnapshot, deleteDoc } from 'firebase/firestore'


import {  signOut, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import {auth, provider, db} from './firebase.config.js';
import { getDatabase, ref, push, onValue } from 'firebase/database';
import { useLocation } from 'react-router-dom';

import Account from './page/Account';

import { useAuthState } from 'react-firebase-hooks/auth';

import SignIn from './components/SignIn/SignIn';


import { Routes, Route, Link } from 'react-router-dom';


function App() {

  const location = useLocation();
  const [user] = useAuthState(auth);
  let useruid = '';


  const [loader, setLoader] = useState(true);

  const [recipes, setRecipes] = useState([]);
  const [addRecipe, setAddRecipe] = useState(false);
  const [search, setSearch] = useState('');
  const [weekList, setWeekList] = useState(JSON.parse(localStorage.getItem('weekList')) || []);
  const [displayWeekList, setDisplayWeekList] = useState(false);

  const [isAccountOpen, setIsAccountOpen] = useState(false);


  const loadRecipes = async (id) => {
  setLoader(true);
  const recipesRef = collection(db, `users/${id}/recipes`);
  const recipesQuery = query(recipesRef);
  const sharedWithRef = doc(db, "users", id);
  const sharedWithDoc = await getDoc(sharedWithRef);
  const sharedWith = [id, ...sharedWithDoc.data().sharedWith];

  const promises = sharedWith.map(async (userId) => {
    const userRecipesRef = collection(db, `users/${userId}/recipes`);
    const userRecipesQuery = query(userRecipesRef);
    const userRecipesSnapshot = await getDocs(userRecipesQuery);
    const userRecipes = userRecipesSnapshot.docs.map((doc) => doc.data());
    return userRecipes;
  });

  const recipes = await Promise.all(promises);
  const mergedRecipes = [].concat(...recipes);
  setRecipes(mergedRecipes);
  setLoader(false);
  };

  useEffect(() => {
    if (user) {
      useruid = user.uid;
      loadRecipes(user.uid);
    }
  }, [user]);

  useEffect(() => {
    if (location.pathname === "/") {
      setIsAccountOpen(false);
    }
  }, [location]);

  console.log(recipes);
  console.log(recipes.length)
  console.log(user);
// console.log(user.uid);

  const onSave = async (newRecipe) => {

    try {
    // Add the new recipe to the "recipes" collection in the database
      const docRef = await addDoc(collection(db, `users/${user.uid}/recipes`), newRecipe);
      console.log('Recipe saved to database with ID: ', docRef.id);
    } catch (error) {
      console.error('Error saving recipe to database:', error);
    }

  // Update the state with the new recipe
   // setRecipes((prevRecipes) => [...prevRecipes, newRecipe]);
    setAddRecipe(false);
  } 

  const addRecipeToWeekList = (recipe) => {
    const recipeExists = weekList.some((r) => r.id === recipe.id);
    if (!recipeExists) {
      setWeekList([...weekList, recipe]);
      localStorage.setItem('weekList', JSON.stringify([...weekList, recipe]));
    }
  }

  const clearWeekList = () => {
    setWeekList([]);
  }

  const addRandomRecipe = () => {
    console.log('click');
    // Get all recipes that are not already in the weeklist
    const availableRecipes = recipes.filter(recipe => !weekList.includes(recipe));
    console.log('********');
    console.log(availableRecipes);
  // If there are no available recipes, return early
    if (availableRecipes.length === 0) {
      console.log('no avail');
      return;
    }

  // Pick a random recipe from the available recipes
    const randomIndex = Math.floor(Math.random() * availableRecipes.length);
    const randomRecipe = availableRecipes[randomIndex];

  // Add the random recipe to the weeklist using addRecipeToWeekList function
    addRecipeToWeekList(randomRecipe);
  }

  const removeFromWeekList = (id) => {
    const tempArray = weekList.findIndex((recipe) => recipe.id === id);
    if (tempArray !== -1) {
    // Remove the recipe from the weekList
      const updatedWeekList = [...weekList];
      updatedWeekList.splice(tempArray, 1);

    // Update the weekList state
      setWeekList(updatedWeekList);
      localStorage.setItem('weekList', JSON.stringify(updatedWeekList));
    }
  }

  const deleteRecipe = async (id) => {
    setLoader(true);
    if (window.confirm("Zeker dat je dit recept wil verijderen?")) {
      try {
      // Query the database for the recipe document with the matching id
        const q = query(collection(db, `users/${user.uid}/recipes`), where('id', '==', id));
        const querySnapshot = await getDocs(q);

      // If there are multiple documents with the same id, only delete the first one
        if (!querySnapshot.empty) {
          const docRef = querySnapshot.docs[0].ref;
          await deleteDoc(docRef);
        }

      // Remove the recipe from the state
        const updatedRecipes = recipes.filter((recipe) => recipe.id !== id);
        setRecipes(updatedRecipes);
        setLoader(false);
      } catch (error) {
        console.error('Error deleting recipe:', error);
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };


  const handleLogout = () => {               
    signOut(auth).then(() => {
        // Sign-out successful.
      console.log("Signed out successfully")
    }).catch((error) => {
        // An error happened.
    });
  }


  auth.onAuthStateChanged(function(user) {
    if (user) {
   // alert(user.uid);
    } else {
    // No user is signed in.
      alert('signed in');
    }
  });

  if(user) {
    return (
      <div className="App">

       <Routes>
        <Route path="/account" element={<Account setIsAccountOpen={setIsAccountOpen} user={user} />} />
      </Routes>



      <Navbar
      setAddRecipe={setAddRecipe}
      addRecipe={addRecipe}
      displayWeekList={displayWeekList}
      setDisplayWeekList={setDisplayWeekList}
      isAccountOpen={isAccountOpen}
      setIsAccountOpen={setIsAccountOpen}
      user={user}
      />


      <section className={isAccountOpen ? "searchSection hideMe" : "searchSection"}>
      <h1>Wat staat er op de menu deze week, {user.displayName.split(" ")[0]}?</h1>
      <h2 className="subtitle">Kies uit {recipes.length} gerechten</h2>
      <SearchItem search={search} setSearch={setSearch}  />
      
      </section>
      

      <main className={isAccountOpen && "hideMe"}>
      {loader ? (<p>Loading...</p>) : (
        <>
        <MainGrid 
        recipes={recipes.filter(item => ((item.title).toLowerCase()).includes(search.toLowerCase()))} 
        deleteRecipe={deleteRecipe} 
        addRecipeToWeekList={addRecipeToWeekList}
        setRecipes={setRecipes}
        setAddRecipe={setAddRecipe}
        user={user}
        />
        
        {addRecipe && <RecipeForm onSave={onSave} setAddRecipe={setAddRecipe} />}
        </>
        )}
      </main>
      _______

      <WeekList 
      weekList={weekList}
      removeFromWeekList={removeFromWeekList}
      displayWeekList={displayWeekList}
      setDisplayWeekList={setDisplayWeekList} 
      addRandomRecipe={addRandomRecipe}
      clearWeekList={clearWeekList}
      />

      
      </div>


      );
  } else {
    return (
      <SignIn />
      )
  }

}

export default App;
