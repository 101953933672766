import { useState } from 'react';

function RecipeIngredients({recipe}) {

	const [isOpen, setIsOpen] = useState(false);

  	const toggleIngredients = () => {
    	setIsOpen(!isOpen);
  	}

	return (
		<ul className="ingredienten">
        	<h3 onClick={toggleIngredients}>Ingrediënten {isOpen ? <span>-</span> : <span>+</span>}</h3>
        	 {isOpen && (
        	 	<>
        			{recipe.ingredients.map((ingredient, index) => (
        			<li key={index}>{ingredient}</li>
        	))}
        		</>
        		)}
       	</ul>
	)
}

export default RecipeIngredients