import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom';
import './index.css';
import App from './App';
import Account from './page/Account'
import {
  BrowserRouter,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";

const root = createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
        <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<App />} />
          <Route path="/account" element={<Account />} />
        </Route>
      </Routes>
    
  </BrowserRouter>
);

