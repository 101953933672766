import { initializeApp } from "firebase/app";
import { GoogleAuthProvider,getAuth, signOut} from "firebase/auth";
import {getFirestore, query, getDocs, collection, where, addDoc } from "firebase/firestore";
import { useAuthState } from 'react-firebase-hooks/auth';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCg5eOJwTNLnjLXtp6q8iTQ7GboOQ3NRx4",
  authDomain: "eetwat-231d7.firebaseapp.com",
  projectId: "eetwat-231d7",
  storageBucket: "eetwat-231d7.appspot.com",
  messagingSenderId: "999915416032",
  appId: "1:999915416032:web:a579954adfa702c0fda385"
};

const app = initializeApp(firebaseConfig);

// firebase auth
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const storage = getStorage(app);

// google auth


// We’ll import db anytime we need to access the database.
// We’ll import auth anytime we need to authenticate with Firebase.
// We’ll import provider anytime we need to authenticate with Google.

export {
  auth,
  db,
  provider,
  storage
};