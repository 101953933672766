import { Link } from 'react-router-dom';

function Navbar({setAddRecipe, 
								addRecipe, 
								displayWeekList, 
								setDisplayWeekList, 
								isAccountOpen, 
								setIsAccountOpen, 
								user }) {
	

	return (
		<>
	<nav>
        <a href="#" onClick={() => setAddRecipe(!addRecipe)}>➕</a>
        <a href="#" onClick={() => setDisplayWeekList(!displayWeekList)}>📝</a>
        {isAccountOpen ? (<Link to="/" onClick={() => setIsAccountOpen(false)}>🏠</Link>) : (<Link user={user} to="/account">⚙️</Link>)}
      </nav>
    </>
	)
}

export default Navbar