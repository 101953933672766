import { useRef, useState, useEffect } from 'react';
import { db } from '../firebase.config.js';
import { doc, updateDoc, getDoc, setDoc, arrayUnion} from 'firebase/firestore'

function Account({setIsAccountOpen, user}) {


	const [sharedWith, setSharedWith] = useState(null);
	console.log('Account');
	setIsAccountOpen(true);
	const addUserInput = useRef(null);
	const userRef = doc(db, "users", user.uid);

	const handleLogout = () => {

	}

	const handleAddUser = async (e) => {
  e.preventDefault();
  const userUID = addUserInput.current.value;

  try {
    // Check if the current user is already in the users collection
    const userDoc = await getDoc(userRef);

    if (!userDoc) {
      // If the user document does not exist, create a new document with the user's UID
      await setDoc(userRef, {});
    }

    // Check if the user with the given UID exists in the users collection
    const addedUserRef = doc(db, "users", userUID);
    const addedUserDoc = await getDoc(addedUserRef);

    if (addedUserDoc) {
      // Add the UID to the current user's "sharedWith" field
      await updateDoc(doc(db, "users", user.uid), {
        sharedWith: arrayUnion(userUID)
      });

      // Add the UID of the current user to the added user's "sharedWith" field
      await updateDoc(doc(db, "users", userUID), {
        sharedWith: arrayUnion(user.uid)
      });

      alert('User added successfully!');
    } else {
      alert(`User with UID ${userUID} does not exist.`);
    }
  } catch (error) {
    console.error('Error adding user:', error);
    alert('An error occurred while adding the user. Please try again later.');
  }
};

	useEffect(() => {

		getDoc(userRef).then((doc) => {
			if (doc) {
				setSharedWith(doc.data().sharedWith);
			} else {
				console.log('No such document!');
			}
		})

	}, []);
	
	

	return (
		<div className="accountPage">
		<h1>Hello Account</h1>
		<a href="/" onClick={handleLogout}>
		Log out
		</a>
		<h4>Your user ID is <span>{user.uid}</span></h4>
		<form onSubmit={handleAddUser}>
		<label htmlFor="userUID">Add User UID:</label>
		<input
		type="text"
		id="userUID"
		ref={addUserInput}
		/>
		<button type="submit">Submit</button>
		</form>
      <div><h3>Added users</h3>
      	{sharedWith != null && sharedWith.map((item) => (
      		<span key={item}>{item}</span>))}
      </div>
		</div>
		)
}

export default Account