import { useState } from 'react';
import RecipeForm from '../RecipeForm/RecipeForm';
import { query, updateDoc, where, collection, getDocs} from 'firebase/firestore'
import { db} from '../../firebase.config.js';
import RecipeIngredients from './RecipeIngredients'


function MainGrid({recipes, deleteRecipe, addRecipeToWeekList, setRecipes, setAddRecipe, user}) {

	const [selectedRecipe, setSelectedRecipe] = useState(null);
	const [editingRecipe, setEditingRecipe] = useState(null);

const handleSaveRecipe = async (editedRecipe) => {
	console.log('good');
  try {
    const recipeRef = collection(db, `users/${user.uid}/recipes`);
    const q = query(recipeRef, where('id', '==', editedRecipe.id));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      await updateDoc(docRef, editedRecipe);
    }
    
    const updatedRecipes = recipes.map((recipe) =>
      recipe.id === editedRecipe.id ? editedRecipe : recipe
    );
    setRecipes(updatedRecipes);
    setEditingRecipe(null);
  } catch (error) {
    console.error('Error saving recipe:', error);
  }
};

const resetView = () => {
	console.log('hai');
	  setSelectedRecipe(null);
  setEditingRecipe(null);
}

	if(recipes.length === 0) {
			return <div>No Results Found, sorry.</div>;
	}


	return (
		<> 

	{ recipes.map((recipe) => {
        	return (

        	<>
        	 <div className="recipe" key={recipe.id}>
        	 <img src={recipe.imageurl == '' ? ('https://via.placeholder.com/150') : (recipe.imageurl) } onClick={() => selectedRecipe ? setSelectedRecipe(null) : setSelectedRecipe(recipe.id)} />
        	 <h3 onClick={() => selectedRecipe ? setSelectedRecipe(null) : setSelectedRecipe(recipe.id)}>{recipe.title}</h3>
        	 <h4>Tijd: {recipe.time}</h4>
        		<button className="addToWL" onClick={() => addRecipeToWeekList(recipe)}>🔥</button>
        	</div>
        		{selectedRecipe === recipe.id && <div className="recipeDetails">
        		<div className="closeMe" onClick={resetView}>X</div>
        			{recipe.imageurl == "" ? ( <img src="https://via.placeholder.com/150" /> ) : 
        			( <img src={recipe.imageurl} /> )
  						
  				}<div className="metawrapper"><h1>{recipe.title}</h1>
  					<span>👨‍🍳 Added by <b>{recipe.auteur}</b></span>
  					<span>⏰ {recipe.time}</span><span>{recipe.season}</span></div>
  					<div className="ingredientenBlock">
        				<RecipeIngredients recipe={recipe} />
        			</div>
<ol className="steps">
  <h3>Stappen</h3>
  {recipe.steps.map((step, index) => (
    <li dangerouslySetInnerHTML={{ __html: step }} key={index} />
  ))}
</ol>

        			<div className="buttons">
        				<button className="aToWeekList" onClick={() => addRecipeToWeekList(recipe)} >🔥</button>
        				<button onClick={() => deleteRecipe(recipe.id)}>Detele recipe</button>
        				<button onClick={() => setEditingRecipe(recipe.id)}>Edit recipe</button>
        			</div>
        		</div> 

        	}
        	
        	           {editingRecipe === recipe.id && (
              <RecipeForm 
              recipe={recipe} 
              onSave={handleSaveRecipe}
              setAddRecipe={setAddRecipe}
              handleSaveRecipe={handleSaveRecipe}
              setEditingRecipe={setEditingRecipe}
               />
            )}

        	</>
        	)
       	})
    }	
    	</>
	)
}

export default MainGrid